import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "stateSelect", "subjectSelect", "cardsContainer", "results", "resultsHeading"]
  static classes = ["card", "cards"]
  static values = {
    url: { type: String, default: "/search" },
    colors: { type: Array, default: ["aqua", "orange", "blue", "pink", "purple"] },
    cardColor: String,
    siteId: String,
    showSearchQuery: { type: Boolean, default: true }
  }

  get stateValue() {
    return this.hasStateSelectTarget ? this.stateSelectTarget.value : ""
  }

  get subjectValue() {
    return this.hasSubjectSelectTarget ? this.subjectSelectTarget.value : ""
  }

  getCardColor=(index) => {
    if (this.cardColorValue) {
      return this.cardColorValue
    } else {
      return this.colorsValue[(index++ % this.colorsValue.length)]
    }
  }

  get resultsHeading() {
    if (this.showSearchQueryValue && this.inputTarget.value) {
      return "“" + this.inputTarget.value + "”"
    }
  }

  connect() {
    let { inputTarget, renderCard } = this

    $(inputTarget).autocomplete({
      minLength: 0,
      delay: 300,
      appendTo: this.resultsTarget,
      classes: {
        "ui-autocomplete": this.cardsClasses.join(" "),
        "ui-helper-hidden-accessible": "hidden"
      },
      source: (request, response) => { this.fetchData(request, response) },
      close: () => { $("ul.ui-autocomplete").show() }
    })

    $(inputTarget).data("ui-autocomplete")._renderItem = function(div, item) {
      return $(renderCard(item)).appendTo(div)
    }

    $(inputTarget).data("ui-autocomplete")._resizeMenu = function() {
      this.menu.element.css("width", "inherit")
    }
  }

  search() {
    $(this.inputTarget).autocomplete("search", this.inputTarget.value)
  }

  fetchData(request, response) {
    $.ajax({
      url: this.urlValue,
      type: "GET",
      dataType: "json",
      data: this.queryData,
      success: (data) => this.success(data, response)
    })
  }

  get queryData() {
    var data = { query_term: this.inputTarget.value }

    if(this.siteIdValue) { data["site_id"] = this.siteIdValue }
    if(this.hasSubjectSelectTarget) { data[this.subjectSelectTarget.name] = this.subjectSelectTarget.value }
    if(this.hasStateSelectTarget) { data[this.stateSelectTarget.name] = this.stateSelectTarget.value }

    return data
  }

  handleEmptyResult() {
    if (this.isEmptyInputs()) {
      this.toggleSearchResultContainer(true)
    } else {
      this.toggleSearchResultContainer(false)
      this.showEmptyResult()
    }
  }

  success(data, response) {
    let { getCardColor, toggleSearchResultContainer } = this

    if (!data.articles.length) {
      this.handleEmptyResult()
    } else {
      toggleSearchResultContainer(this.isEmptyInputs())

      if (!this.isEmptyInputs()) {
        this.resultsHeadingTarget.textContent = this.resultsHeading

        response(data.articles.map(function(article, index) {
          return Object.assign(article, { color: getCardColor(index) })
        }))
      }
    }
  }

  toggleSearchResultContainer=(state) => {
    let cardsDisplayClass   = state ? "block" : "none",
        resultsDisplayClass = state ? "none" : "block"

    this.cardsContainerTarget.style.display = cardsDisplayClass
    this.resultsTarget.style.display = resultsDisplayClass
    this.resultsHeadingTarget.style.display = resultsDisplayClass
  }

  renderCard=(item) => {
    let headerData = item.published_on || this.generateBreadcrumb(item.topic_name, item.sub_topic_name),
        card = document.createElement("a"),
        cardContent = `
          <div class="card__header">
            <img class="card__header__img" src="${item.featured_image_url}"/>
          </div>
          <div class="card__content">
            <span class="card__content-header">${headerData}</span>
            <span class="card__content-footer">${item.read_time} min read </span>
            <div class="card__content-title">${item.title}</div>
            <div class="card__content-description card__content-description--search-result">${this.highlightTerm(item.description)}</div>
          </div>
        `

    card.href = item.url
    card.className = `card card--${item.color} card--responsive ${this.cardClass}`
    card.insertAdjacentHTML("afterbegin", cardContent)

    return card
  }

  generateBreadcrumb() {
    return Array.from(arguments).filter(item => item).join(" / ")
  }

  highlightTerm(text) {
    return text.replace(
      new RegExp("(?![^&;]+;)(?!<[^<>]*)(" + $.ui.autocomplete.escapeRegex(this.inputTarget.value) + ")(?![^<>]*>)(?![^&;]+;)", "gi"),
      "<strong>$1</strong>"
    );
  }

  isEmptyInputs() {
    return !(this.inputTarget.value || this.stateValue || this.subjectValue)
  }

  showEmptyResult() {
    this.resultsHeadingTarget.textContent = "No search results"
    $("ul.ui-autocomplete").hide()
    this.cardsContainerTarget.style.display = "none"
  }
}
