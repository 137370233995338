import { html } from "gridjs";
import dataTableController from "./data_table_controller";

export default class extends dataTableController {
  columns() {
    return [
      { name: this.headers.ca_name },
      {
        name: this.headers.provider_count,
        sort: this.element.sharedTable.intSorter(),
        attributes: this.element.sharedTable.setAttributes
      },
      {
        name: this.headers.injury_count,
        sort: this.element.sharedTable.intSorter(),
        attributes: this.element.sharedTable.setAttributes
      },
      {
        name: this.headers.bill_count,
        sort: this.element.sharedTable.intSorter(),
        attributes: this.element.sharedTable.setAttributes
      },
      {
        name: this.headers.most_frequent_mode,
        attributes: this.element.sharedTable.setAttributes
      },
      {
        name: this.headers.duplicate_percent,
        sort: this.element.sharedTable.intSorter(),
        attributes: this.element.sharedTable.setAttributes
      },
      {
        name: this.headers.avg_days,
        sort: this.element.sharedTable.intSorter(),
        attributes: this.element.sharedTable.setAttributes
      },
      {
        id: "link",
        name: "",
        formatter: (cell) => html(`<a href='/claims-administrator-profile/${cell}'>View</a>`)
      }
    ]
  }
}
